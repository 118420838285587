import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer-3 footer-topbar light page-section-pt">
        <div className="container">
          <div className="row top">
            <div className="col-lg-3 col-md-12">
              <img
                className="img-fluid"
                id="logo_footer_dark"
                src="images/logo-dark.png"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="social text-lg-right text-center">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/rofemotorsudia">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/rofe_motors/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send?phone=5534998970004&text=Ol%C3%A1%2C%20vi%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20seus%20produtos.">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/maps/place/Rofe+Motors/@-18.9009366,-48.2600967,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445d12f73b749:0x81c30dd0645841d2!8m2!3d-18.9009366!4d-48.2600967">
                      <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="row footer_info">
            <div className="col-lg-5 col-md-12">
              <div className="about-content">
                <h6 className="text-black">Sobre nós</h6>
                <p className="text-gray">
                  Com uma postura personalizada no mercado premium de veículos, a Rofe Motors 
                  traz um novo conceito de trabalho e atendimento, visando oferecer sempre o 
                  melhor aos seus clientes. A empresa – que nasceu através de uma paixão por 
                  automóveis – atua nos seguimentos de veículos zero quilômetro e seminovos 
                  de qualquer marca.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <h6 className="text-black">Informações da loja</h6>
              <div className="usefull-link">
                <div className="row">
                  <div className="col-md-4 footer_info">
                    <ul>
                      <li>
                        <a href="https://www.google.com/maps/place/Av.+Rondon+Pacheco,+5443+-+Brasil,+Uberl%C3%A2ndia+-+MG,+38400-655/@-18.9009315,-48.2622854,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445be0bc94319:0x90f7b4eaab8f2a07!8m2!3d-18.9009366!4d-48.2600967">
                          <i className="fa fa-map-marker"></i>
                          Av. Dos Vinhedos, 70 Vinhedos Office Sala 1303 - Bairro Jardim Karaiba, Uberlândia/MG - CEP: 38411-217
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-phone"></i>
                          (34) 3225-5990
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-whatsapp"></i>
                          (34) 9 9897-0004
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-envelope-o"></i>
                          contato@rofemotors.com.br
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p>
                    © Copyright 2021 ROFE Motors | Todos os direitos reservados.
                    <a href="https://intermedioveiculos.com.br/" target="_blank"
                    > Intermédio Veículos Marketing Digital</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;